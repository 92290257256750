<template>
  <div>
    <div class="top" style="display: flex; margin-bottom: 20px">
      <div class="ml" style="display:flex;margin-right:20px;align-item:center">
        <div style="width:80px;margin-top:10px">手机号</div>
        <el-input type="text" placeholder="手机号" :disabled="!search" v-model="model" width="80px" />
      </div>
      <div class="ml">
        <span style="margin-right: 10px">访问时间</span>
        <el-date-picker
          :disabled="!search"
          v-model="datevalue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </div>
      <div class="ml">
        <span style="margin: 0 1+0px 0px 30px">场地名称</span>
        <el-select v-model="addressid" filterable placeholder="全部" :disabled="!search">
          <el-option
            v-for="item in address_list"
            :key="item.id"
            :label="item.address_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <el-button type="primary" style="margin-left: 10px" @click="searcht2()" v-if="search">查询</el-button>
      <el-button type="warning" style="margin-left: 30px" @click="tuisearch2()" v-else>取消搜索</el-button>
    </div>

    <!-- 表格 -->
    <el-table @row-click="rowclick" :data="tableData" border style="width: 100%; max-height: 65vh; overflow-y: scroll">
      <el-table-column prop="address.address_name" label="场地名称"></el-table-column>
      <el-table-column prop="user.user_id" label="用户id"></el-table-column>
      <el-table-column prop="vehicle.name" label="用户昵称"> </el-table-column>
      <el-table-column prop="vehicle.mobile" label="手机号码"> </el-table-column>
      <el-table-column prop="created_at" label="开门时间"></el-table-column>
      <el-table-column prop="address_access.access_name" label="门禁名称"></el-table-column>
    </el-table>
  </div>
</template>

<script>
import { openTheDoorRecord, addressList1 } from "../../request/api";
import Moment from "moment";
import { number } from "echarts";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      address_list: [],
      search: true,
      model: "",
      datevalue: ["", ""],
      addressid: "",
      tableData: [
        { add: "成电花园", d: 10 },
        { add: "天玺", d: 10 },
      ],
    };
  },
  async created() {
    addressList1().then((res) => {
      this.address_list = res.list;
    });
    this.datevalue = this.$route.query.datevalue ? this.$route.query.datevalue.split(",") : "";
    this.addressid = this.$route.query.addressid ? Number(this.$route.query.addressid) : "";
    console.log(this.datevalue || this.addressid, this.datevalue, this.addressid);
    if (this.datevalue[0] || this.datevalue[1] || this.addressid) {
      this.search = false;
    }
    this.taocandimens();
  },
  watch: {
    page() {
      this.taocandimens();
    },
    pagepage() {
      this.taocandimens();
    },
  },
  methods: {
    rowclick(row) {
      this.$router.push("/Userdetail?id=" + row.user_id);
    },
    searcht2() {
      this.taocandimens();
      this.search = false;
    },
    tuisearch2() {
      this.datevalue = "";
      this.addressid = "";
      this.model = "";
      this.taocandimens();
      this.search = true;
    },

    taocandimens() {
      return new Promise((resolve, reject) => {
        openTheDoorRecord({
          page: this.page,
          user_id: "-1",
          phone: this.model,
          limit: this.pagepage,
          start_time: this.datevalue[0] ? Moment(this.datevalue[0]).format("YYYY-MM-DD HH:mm:ss") : "",
          end_time: this.datevalue[1] ? Moment(this.datevalue[1]).format("YYYY-MM-DD HH:mm:ss") : "",
          address_id: this.addressid,
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.total);
          this.tableData = res.list;
        });
        this.tableData = [
          { add: "成电花园", d: 10 },
          { add: "天玺", d: 10 },
        ];
      });
    },
  },
};
</script>
<style lang="less" scoped>
.userexamine {
  /deep/.cell {
    text-align: center;
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
}
</style>
