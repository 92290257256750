<template>
  <!-- 订单查询 -->
  <div class="OrderSearch">
    <div class="top">
      <div class="ml">
        <span style="margin-right: 10px">用户名/手机号</span>
        <el-input
          v-model="searchval.username"
          placeholder="请输入用户名/手机号"
          style="width: 180px"
          :disabled="!search"
        ></el-input>
      </div>
      <div class="ml">
        <span style="margin-right: 10px">订单状态</span>
        <el-select v-model="searchval.ordertype" filterable placeholder="全部" style="width: 130px" :disabled="!search">
          <el-option label="未支付" value="0"></el-option>
          <el-option label="已支付" value="1"></el-option>
        </el-select>
      </div>
      <div class="ml">
        <span style="margin-right: 10px">套餐状态</span>
        <el-select
          v-model="searchval.taocantype"
          filterable
          style="width: 150px"
          placeholder="全部"
          :disabled="!search"
        >
          <el-option label="使用中" value="1"></el-option>
          <el-option label="已过期" value="2"></el-option>
        </el-select>
      </div>
    </div>
    <div class="top spaves" style="margin-top:30px">
      <div>
        <span style="margin-right: 10px">下单时间</span>
        <el-date-picker
          style="width: 353px"
          :clearable="false"
          :disabled="!search"
          v-model="searchval.datevalue"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
        <el-button type="primary" style="margin-left: 10px" @click="searcht2()" v-if="search">查询</el-button>
        <el-button type="warning" style="margin-left: 30px" @click="tuisearch2()" v-else>取消搜索</el-button>
      </div>
      <div class="tots">当前总额： {{ totalPrice }} 元</div>
    </div>
    <el-table
      @row-click="rowclick"
      :data="tableData"
      border
      style="width: 100%; margin-top: 30px; max-height: 65vh;  overflow-y: scroll;"
    >
      <el-table-column prop="order_on" label="订单号" width="200px"></el-table-column>
      <el-table-column label="用户名" width="150px">
        <template #default="scoped">
          <span @click="$router.push('/userdetail?id=' + scoped.row.user_id)">{{ scoped.row.user_name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="头像" width="150px">
        <template #default="scoped">
          <img style="width:45px;height:45px;border-radius:50%;" :src="scoped.row.head_pic" />
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" width="200px"></el-table-column>
      <el-table-column prop="status" label="订单状态"></el-table-column>
      <el-table-column prop="price" label="订单价格"></el-table-column>
      <el-table-column prop="createtime" label="下单时间" width="200px"></el-table-column>
      <el-table-column prop="paytime" label="支付时间" width="200px"></el-table-column>
      <el-table-column prop="pake_name" label="套餐名称" width="150px"></el-table-column>
      <el-table-column prop="pake_status" label="套餐状态"></el-table-column>
      <el-table-column label="操作" width="118px">
        <template #default="scope">
          <el-button @click.stop="handleClick(scope.row)" type="warning" size="small">退款</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="centerDialogVisible" width="25%" center>
      <div
        style="
            text-align: center;
            width: 100%;
            font-weight: 500;
            font-size: 18px;
          "
      >
        是否确定退款
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="tuiKuanClick()">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { orderOrderList, ordersd, orderRefund } from "../../request/api";
import { ElMessage } from "element-plus";
import { number } from "echarts";
import Moment from "moment";
import dayjs from "dayjs";
export default {
  props: ["page", "pagepage"],
  data() {
    return {
      data: "",

      tableData: [],
      tableDataCopy: [],
      search: true,
      pageTotal: "",
      centerDialogVisible: false,
      order_sn: "",
      totalPrice: 0,
      address_list: [],
      searchval: {
        orderinput: "", //设备号
        username: "",
        mobile: "",
        ordertype: "",
        taocantype: "",
        datevalue: "",
        addresssname: "",
      },
    };
  },

  async created() {
    this.orderList();
  },
  methods: {
    handleClick(row) {
      this.order_sn = row.order_sn;
      this.centerDialogVisible = true;
    },
    tuiKuanClick() {
      ElMessage.error("没有权限");
    },
    dateChangebirthday(val) {
      this.form.birthdayName = val;
    },
    orderList() {
      return new Promise((resolve, reject) => {
        console.log(this.searchval.ordertype);
        ordersd({
          s: (this.page - 1) * this.pagepage,
          e: this.pagepage,
          createtime1: this.searchval.datevalue
            ? Moment(this.searchval.datevalue[0]).format("YYYY-MM-DD HH:mm:ss")
            : "",
          createtime2: this.searchval.datevalue
            ? Moment(this.searchval.datevalue[1]).format("YYYY-MM-DD HH:mm:ss")
            : "",
          text: this.searchval.username,
          pake_status: this.searchval.taocantype != 0 ? Number(this.searchval.taocantype) : this.searchval.taocantype,
          status: this.searchval.ordertype !== "" ? Number(this.searchval.ordertype) : undefined,
        }).then((res) => {
          resolve(res.total);
          this.$emit("pagetotalClick", res.list.count);
          this.totalPrice = res.list.pricesum.toFixed(2);
          this.tableData = res.list.list;
          this.tableData.forEach((item) => {
            item.status =
              item.status == 0
                ? "未支付"
                : item.status == 1
                ? "已支付"
                : item.status == 2
                ? "订单完成"
                : item.status == 3
                ? "订单退款"
                : "订单取消";
            item.pay_status = item.pay_status == 0 ? "未支付" : item.pay_status == 1 ? "已支付" : "已退款";
            item.pake_status =
              item.pake_status == 1
                ? "使用中"
                : item.pake_status == 2
                ? "已过期"
                : item.pake_status == 3
                ? "退款"
                : item.pake_status == 0
                ? "未支付"
                : item.pake_status;
          });
          this.tableDataCopy = this.tableData;
        });
      });
    },

    searcht2() {
      this.search = false;
      this.orderList();
    },
    rowclick(row) {
      this.$router.push("/Userdetail?id=" + row.user_id);
    },
    tuisearch2() {
      this.search = true;
      this.searchval = {
        orderinput: "",
        username: "",
        mobile: "",
        ordertype: "",
        taocantype: "",
        datevalue: "",
        addresssname: "",
      };
      this.orderList();
    },
  },
  watch: {
    page() {
      if (this.search) {
        this.orderList();
      } else {
        this.searcht2();
      }
    },
    pagepage() {
      // console.log(pagepage)
      if (this.search) {
        this.orderList();
      } else {
        this.searcht2();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  display: flex;
  justify-content: start;
  flex-wrap: nowrap;

  .ml {
    margin-right: 50px;
  }
}

.spaves {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tots {
    font-weight: bold;
  }
}

/deep/.cell {
  text-align: center;
}
</style>
